.margin-left-0 {
  margin-left: 0 !important;
}
.s-marker {
  padding: 20px 40px 15px 15px;
  width: 816px;
  background-color: #fff;
  position: absolute;
  bottom: 30px;
  left: -580px;
  z-index: 1;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.17);
  cursor: default;
  font-size: 0.75rem;
}
.s-marker:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  bottom: -10px;
  left: 570px;
}
.s-marker .close {
  display: block;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: 0.2s;
  cursor: pointer;
}
.s-marker .close:hover {
  color: #a3a3a3;
}
.s-marker .close:active {
  color: #a3a3a3;
}
.s-marker .close .icon {
  font-size: 25px;
}
.s-marker .dealer {
  padding: 0;
  border: 0;
}
.s-marker .dealer .content_container .cars-list_container .cars-list {
  max-height: 260px;
  overflow: auto;
}
.s-marker .dealer .content_container .cars-list_container .cars-list .car_container {
  padding: 5px 10px;
}
.s-marker .dealer .content_container .cars-list_container .cars-list .car_container .car-info_container .car-info_block {
  width: 198px;
}
.s-marker .dealer .content_container .cars-list_container .cars-list .car_container .car-info_container .car-info_block .color_container .name {
  width: 142px;
}
.s-marker .dealer .content_container .cars-list_container .cars-list .car_container .car-info_container .car-info_block .stock-update-date {
  bottom: 8px;
  font-size: 11px;
}
@media (max-width: 1240px) and (min-width: 1100px) {
  .s-marker {
    width: 700px;
  }
  .s-marker .dealer {
    padding: 0;
    border: 0;
  }
}
